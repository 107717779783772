import React, {useState, useEffect, useCallback} from 'react';

import useAuth from '../../hooks/useAuth.js';
import useLogout from '../../hooks/useLogout.js';
import axios from "../../api/axios.js";
import TooltipLite from 'react-tooltip-lite';

import MUIDataTable from "mui-datatables";

import Sidebar from '../sidebar/index.js';
import {SidebarContainer,ViewContainer,HeaderBar,ActionDiv,ButtonSection,DataSection,
    ButtonSectionHeader,ButtonSectionContent,BackButton,DBAformcontainer,DBAformline,
    DBAformwrapper,SingleInput,SingleName,RowButtonSection,ButtonAction,InternalButton
    ,ButtonIcon,ActionContainer,ButtonWrapper,InternalCancelButton,ActionInput,
    ViewWrapper} from './dbaElements.js';
import UploadForm from '../uploadform/index.js';
import DbaNewUser from '../dbanewuser/index.js';

import pwdicon from '../../img/pwdicon.svg';
import roleicon from '../../img/roleicon.svg';
import deleteicon from '../../img/deleteicon.svg';
import uploadicon from '../../img/uploadicon.svg';
import enableicon from '../../img/enableicon.svg';

/*const APIGETUSERS = 'https://test2.3dabackend.com/censor/list';
const APICHANGEROLE = 'https://test2.3dabackend.com/censor/chrole';
const APIDELETEUSER = 'https://test2.3dabackend.com/censor/delete';
const APICHANGEPWD = 'https://test2.3dabackend.com/censor/chpass-dba';
const APIDEACTIVATE = 'https://test2.3dabackend.com/censor/deactivate';
const APIACTIVATE = 'https://test2.3dabackend.com/censor/activate';
*/
const APIGETUSERS = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/list`;
const APICHANGEROLE = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/chrole`;
const APIDELETEUSER = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/delete`;
const APICHANGEPWD = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/chpass-dba`;
const APIDEACTIVATE = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/deactivate`;
const APIACTIVATE = `${process.env.REACT_APP_API_BASE_URL}/censor/admin/activate`;
const APILOGOUT = `${process.env.REACT_APP_API_BASE_URL}/censor/logout`;

const DBA = () => {
    const { auth } = useAuth();

    const [selectedAction, setSelectedAction] = useState("default");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState(null);   
    const [newPassword, setNewPassword] = useState('');
    const [userList, setUserList] = useState(null);

    const [loading, setLoading] = useState(false);
    
    const logout = useLogout();

    // API CALLS

    useEffect( () => {
        if (auth?.accessToken) {
        getUsers();
        }
    }, [auth?.accessToken]);

    

    const handleLogout = async () => {
        try {
            console.log("attivo utente");
            const response = await axios.get(APILOGOUT, {
            "idUtente": auth.id
            }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth?.accessToken}`,
                'id': auth.id,
                // 'ngrok-skip-browser-warning': 'true',
            }
            })
            .then(response => {
            // if(response.data?.success){
            if(response.data?.data?.success){
                console.log("success");
              }
              // if(!response.data?.success){
              if(!response.data?.data?.success){
                console.log("not success");
                console.log(response)
              } else {
                console.log("not success and not 200");
                console.log(response)
              }
            })
            .catch(error => {
              console.error();
            });
            await getUsers();
        } catch (error) {
            console.error(error);
        }
    }

    const handleList = async () => {
        try {
            console.log("handleList");
            const response = await axios.get('https://0146-87-17-214-243.ngrok-free.app/censor/admin/list', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                    'id': auth.id,
                    //'ngrok-skip-browser-warning': 'true',
                }
            });
    
            console.log("handleList Full response:", response);
    
            if (response.data.success) {
                console.log("handleList success");
            } else {
                console.log("handleList not success");
                console.log("handleList response", response);
            }
        } catch (error) {
            console.error("handleList Request error:", error);
        }
    };

    const getUsers = async () => {
        try {
            console.log("Fetching user list in DBA");
            const response = await axios.get(APIGETUSERS, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                    'Authorization': `Bearer ${auth?.accessToken}`,
                    'id': auth.id,
                    'Content-Type': 'application/json',
                }
            });
    
            console.log("API GET USERS response:", response);
    
            if (response.data?.success) {
                const rawData = response.data.data;
                const modifiedData = rawData.map((item, index) => {
                    return {
                        id: index,
                        ...item,
                        isActive: item.isActive === 1 ? "SI" : "NO"
                    };
                });
                console.log("Modified User Data:", modifiedData);
                setUserList(modifiedData);
            } else {
                console.log("API GET USERS not successful");
                console.log(response);
            }
        } catch (error) {
            console.error("API GET USERS error:", error);
        }
    };
    

    const changePassword = async (idUtente, newPassword) =>{
        try{
            console.log("chiamo censor per change password in DBA");
            const response = await axios.post(APICHANGEPWD, {
                "idUtente" : idUtente, 
                "npsw" : newPassword
                },
                { headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
                })
                .then(response => {
                    // if(response.data?.success){
                    if(response.data?.data?.success){
                        console.log("success");
                        switchToAllActions();
                    }
                    // if(!response.data?.success){
                    if(!response.data?.data?.success){
                        console.log("not success");
                        console.log(response)
                    } else {
                        console.log("not success and not 200");
                        console.log(response)
                    }
                })
                .catch(error => {
                    console.error();
                });
                await getUsers();
        } catch (error) {
            console.error(error);
        }
    }

    const modifyRole = async (idUtente, newRole) =>{
        try{
          console.log("chiamo censor per modificare il ruolo dell'utente "+idUtente);
          const response = await axios.post(APICHANGEROLE, {
              "idUtente" : idUtente, 
              "newRole" : newRole
            },
            { headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
            })
            .then(response => {
            // if(response.data?.success){
            if(response.data?.data?.success){
                console.log("success");
                switchToAllActions();
              }
              // if(!response.data?.success){
              if(!response.data?.data?.success){
                console.log("not success");
                console.log(response)
              } else {
                console.log("not success and not 200");
                console.log(response)
              }
            })
            .catch(error => {
              console.error();
            });
            await getUsers();
        } catch (error) {
          console.error(error);
        }
      }

    const deleteUser = useCallback(async (event, idUtente) => {
        event.stopPropagation();
        setLoading(true);
        console.log("elimino: ")
        console.log(idUtente)
        try {
            console.log("chiamo censor per eliminare l'utente "+idUtente);
            const response = await axios.post(APIDELETEUSER, {
                "idUtente" : idUtente
            }, {
                headers: {
                    'Authorization': `Bearer ${auth?.accessToken}`,
                    'id': auth.id,
                },
            })
            .then(response => {
                // if(response.data?.success){
                if(response.data?.data?.success){
                console.log("success");
                switchToAllActions();
              }
              // if(!response.data?.success){
              if(!response.data?.data?.success){
                console.log("not success");
              } else {
                console.log("not success and not 200");
              }
            })
            .catch(error => {
              console.error();
            });
          //await getUsers();
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, [auth.id]);

    const handleDeactivateUser = async (userId) => {
        try {
          console.log("disattivo utente");
          const response = await axios.post(APIDEACTIVATE, {
            "idUtente": userId
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth?.accessToken}`,
              'id': auth.id
            }
          })
          .then(response => {
            // if(response.data?.success){
            if(response.data?.data?.success){
              console.log("success");
              switchToAllActions();
            }
            // if(!response.data?.success){
            if(!response.data?.data?.success){
              console.log("not success");
              console.log(response)
            } else {
              console.log("not success and not 200");
              console.log(response)
            }
          })
          .catch(error => {
            console.error();
          });
          await getUsers();
        } catch (error) {
          console.error(error);
        }
      };      

    const handleActivateUser = async (userId) => {
        try {
            console.log("attivo utente");
            const response = await axios.post(APIACTIVATE, {
            "idUtente": userId
            }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth?.accessToken}`,
                'id': auth.id
            }
            })
            .then(response => {
            // if(response.data?.success){
            if(response.data?.data?.success){
                console.log("success");
                switchToAllActions();
              }
              // if(!response.data?.success){
              if(!response.data?.data?.success){
                console.log("not success");
                console.log(response)
              } else {
                console.log("not success and not 200");
                console.log(response)
              }
            })
            .catch(error => {
              console.error();
            });
            await getUsers();
        } catch (error) {
            console.error(error);
        }
    };
      

    // CHANGE VIEWS

    const switchToSingleAction = (name) => {
        setSelectedAction(name);
    };

    const switchToAllActions = () => {
        setSelectedUser(null);
        setSelectedUserRole(null);
        setSelectedAction("default");
    };
    
    const handleModifyPassword = (id) => {
        // handle button click with row id
        console.log("modify pwd " + id);
        setSelectedAction("pwd");
        setSelectedUser(id);
    };

    const handleDelete = (id) => {
        // handle button click with row id
        console.log("delete " + id);
        setSelectedAction("delete");
        setSelectedUser(id);
    };

    const handleModifyRole = (id) => {
        // handle button click with row id
        setSelectedAction("modify");
        setSelectedUser(id);
        const selectedUserData = userList.find(user => user.idUtente === id);
        console.log(selectedUser);
        console.log(id);
        setSelectedUserRole(selectedUserData.role);
      };

      
    const handleDeactivationUser = (id,isActive) => {
        // handle button click with row id
        setSelectedUser(id);
        setSelectedAction("deactivate")
    };

    const handleUpload = (id) => {
        // handle button click with row id
        setSelectedUser(id);
        setSelectedAction("upload")
        const selectedUserData = userList.find(user => user.idUtente === id);
        const collectionUrl = selectedUserData.collection;
        const splitUrl = collectionUrl.split('/');
        const collectionuid = splitUrl[splitUrl.length - 1];
        setSelectedCollection(collectionuid);
        console.log(collectionuid)
    };

    // HANDLE PASSWORD CHANGE SUBMIT

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
      };
    
    const handleSubmitPasswordChange = async () => {
        setLoading(true);
        await changePassword(selectedUser, newPassword);
        setLoading(false);
    };

    // TABLE USERS AND RELATIVE BUTTONS

    const columns = [
        {
            name: "anagrafica",
            label: "Anagrafica",
            options: {
            filter: true,
            sort: true,
            searchable: true,
            }
        },
        {
            name: "username",
            label: "Username",
            options: {
            filter: true,
            sort: true,
            searchable: true,
            }
        },
        {
            name: "role",
            label: "Role",
            options: {
            filter: true,
            sort: true,
            searchable: false,
            }
        },
        {
            name: "isActive",
            label: "Attivo",
            options: {
            filter: true,
            sort: true,
            searchable: false,
            }
        },
        {
            name: "modelli",
            label: "Models",
            options: {
            filter: true,
            sort: true,
            searchable: false,
            }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: true,
            searchable: false,
          },
        },
        {
            name: "id", // new column for id
            label: "Actions",
            options: {
              filter: false,
              sort: false,
              searchable: false,
              download: false,
              customBodyRender: (value) => {
                const tempuser = userList[value];
                return (
                    <>
                    { userList[value]?.["role"] !== 3 && ( 
                        <RowButtonSection>
                            <ButtonAction onClick={() => handleModifyPassword(userList[value]?.["idUtente"])}>
                                <TooltipLite content="Cambia Password">
                                    <ButtonIcon src={pwdicon}></ButtonIcon>
                                </TooltipLite>
                            </ButtonAction>
                            <ButtonAction onClick={() => handleModifyRole(userList[value]?.["idUtente"])}>
                            <TooltipLite content="Cambia Ruolo">
                                <ButtonIcon src={roleicon}></ButtonIcon>
                            </TooltipLite>
                            </ButtonAction>
                            <TooltipLite content="Elimina Utente">
                            <ButtonAction onClick={() => handleDelete(userList[value]?.["idUtente"])}>
                                <ButtonIcon src={deleteicon}></ButtonIcon>
                            </ButtonAction>
                            </TooltipLite>
                            <TooltipLite content="Carica Modello">
                            <ButtonAction onClick={() => handleUpload(userList[value]?.["idUtente"])}>
                                <ButtonIcon src={uploadicon}></ButtonIcon>
                            </ButtonAction>
                            </TooltipLite>
                            <TooltipLite content="Abilita / Disabilita Utenza">
                            <ButtonAction onClick={() => handleDeactivationUser(userList[value]?.["idUtente"],userList[value]?.["isActive"])}>
                                <ButtonIcon src={enableicon}></ButtonIcon>
                            </ButtonAction>
                            </TooltipLite>
                        </RowButtonSection>
                    )}
                    </>
                );
              }
            }
          }
        ];
        
        const options = {
            selectableRows: 'none',
          };

    return(
        <>
        <SidebarContainer>
            <Sidebar/>
        </SidebarContainer>
        <ViewContainer>
            <ViewWrapper>
            {/*<HeaderBar>
                DBA
    </HeaderBar>*/}
    <div>
        {/* <button onClick={() => handleLogout()}>LOGOUT</button> */}
        <button onClick={() => getUsers()}>LIST</button>
        {/* <InternalButton onClick={() => switchToSingleAction("create")}>Create New User</InternalButton> */}
    </div>
            {/*<button onClick={() => testAPI()}>test api</button>*/}
            {selectedAction === "default" && userList !== null ? (
                <ActionContainer>
                    <ButtonSection>
                        <ButtonSectionContent>
                            <InternalButton onClick={() => switchToSingleAction("create")}>Create New User</InternalButton>
                            <InternalCancelButton onClick={logout}> Logout</InternalCancelButton>                                                
                        </ButtonSectionContent>
                    </ButtonSection>
                    <DataSection>
                            <MUIDataTable
                                title={"Lista Utenti"}
                                data={userList}
                                columns={columns}
                                options={options}
                                />
                    </DataSection>
                </ActionContainer>
            ) : selectedAction === "create" ? (
                <ActionContainer>
                <InternalCancelButton onClick={switchToAllActions}>BACK</InternalCancelButton>

                {/* CHIAMATA API CREATE USER */}

                <DbaNewUser></DbaNewUser>

                </ActionContainer>
            ) : selectedAction === "delete" ? (
                <ActionContainer>
                <InternalCancelButton onClick={switchToAllActions}>BACK</InternalCancelButton>
                Sei sicuro di voler eliminare l'utente {selectedUser} ?
                
                <ButtonWrapper>
                <InternalCancelButton onClick={switchToAllActions}>Annulla</InternalCancelButton>
                <InternalButton onClick={(event) => deleteUser(event, selectedUser)} disabled={loading}>
                {loading ? 'Caricamento...' : 'Conferma'}
                </InternalButton>
                
                </ButtonWrapper>
                </ActionContainer>
            ) : selectedAction === "pwd" ? (
                <ActionContainer>

                    <InternalCancelButton onClick={switchToAllActions} disabled={loading}>BACK</InternalCancelButton>
                    Inserisci una nuova password per l'utente {selectedUser} .
                    
                    <label>
                        Nuova password:
                        <ActionInput
                        type="text"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        disabled={loading}
                        />
                    </label>
                    <ButtonWrapper>
                        <InternalCancelButton onClick={switchToAllActions} disabled={loading}>
                            Annulla
                        </InternalCancelButton>
                        <InternalButton onClick={handleSubmitPasswordChange} disabled={loading}>
                            {loading ? 'Caricamento...' : 'Conferma'}
                        </InternalButton>
                    </ButtonWrapper>

                </ActionContainer>
            ) : selectedAction === "modify" ? (
                <ActionContainer>
                    <InternalCancelButton onClick={switchToAllActions}>BACK</InternalCancelButton>
                    Seleziona il nuovo ruolo per l'utente {selectedUser} .
                    Attualmente il suo ruolo è {selectedUserRole === 1 ? "STANDARD" : "PREMIUM"}.
                    
                    {/* CALL API MODIFY ROLE */}

                    <ButtonWrapper>
                        <InternalCancelButton onClick={() => modifyRole(selectedUser, 1)}>Standard</InternalCancelButton>
                        <InternalButton onClick={() => modifyRole(selectedUser, 2)}>Premium</InternalButton>
                    </ButtonWrapper>
                    </ActionContainer>
            ) : selectedAction === "deactivate"  ? (
                <ActionContainer>
                    <InternalCancelButton onClick={switchToAllActions}>BACK</InternalCancelButton>
                    {userList.find(user => user.idUtente === selectedUser).isActive === 'SI' ? (
                        <>
                            Esegui la Disattivazione dell'utente {selectedUser} : Attivo.
                            <InternalCancelButton onClick={() => handleDeactivateUser(selectedUser, true)}>Disattiva</InternalCancelButton>
                        </>
                        ) : (
                        <>
                            Esegui l'Attivazione dell'utente {selectedUser} : Disattivo.
                            <InternalButton onClick={() => handleActivateUser(selectedUser, false)}>Attiva</InternalButton>
                        </>
                    )}
                </ActionContainer>
            ) :selectedAction === "upload"  ? (
                <ActionContainer>
                <InternalCancelButton onClick={switchToAllActions}>BACK</InternalCancelButton>
                Esegui l'Upload di un modello per l'utente {selectedUser}.
                <UploadForm key={selectedUser} selectedUser={selectedUser} selectedCollection={selectedCollection}></UploadForm>
                </ActionContainer>
            ) : (
            <>
            empty
            </>
            )}
            </ViewWrapper>
        </ViewContainer>
        </>
    )
}

export default DBA;