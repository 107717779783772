import React, { useContext, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { AuthContextProvider } from '../../authcontext.js';
import {SidebarWrapper,ButtonWrapper,LogoWrapper,Spacer,ButtonImg,LogoutWrapper,LinkButton,NavigationMenuWrapper,LeftSideSidebar,RigthSideSidebar} from './sidebarElements.js';
import useAuth from '../../hooks/useAuth.js';
import logocolorsvg from '../../img/3dportlabwhite.png';

let LogoSmall = require('../../img/3dportlabwhite.png');
let Dashboard = require('../../img/sidebar/dashboard.png');
let Logout = require('../../img/sidebar/logout.png');
let Profile = require('../../img/sidebar/profile.png');
let Analytics = require('../../img/sidebar/analytics.png');
let DBA = require('../../img/sidebar/dba.png');

const Sidebar = (props) => {
    //const {isAdmin } = useContext(AuthContextProvider);
    const { auth } = useAuth();
    const [isAdmin,setIsAdmin] = useState(false);

    
    const logoURL = 'https://test.3danimate.it/logos/';
    let Logo = logoURL + auth.id + '/logo';

    useEffect(() => {
        if (auth.roles === 3) {
            setIsAdmin(true);
            console.log("sono admin");
        } else {
            setIsAdmin(false);
        }
    }, [auth.roles]);


    return(
        <>
        <SidebarWrapper bgColor={props.bgColor}>
            <LeftSideSidebar>
                <LogoWrapper to={"/Dashboard"}>
                    <img src={logocolorsvg} alt="Logo"></img>
                </LogoWrapper>
            </LeftSideSidebar>
            <RigthSideSidebar>
                <NavigationMenuWrapper>
                    {!isAdmin ? (<ButtonWrapper>
                        <LinkButton to={"/Dashboard"}>
                            Libreria
                        </LinkButton>
                    </ButtonWrapper>) : null}
                    {!isAdmin ? (<ButtonWrapper>
                        <LinkButton to={"/Analytics"} >
                            Analytics
                        </LinkButton>
                    </ButtonWrapper>) : null}
                    {!isAdmin ? (<ButtonWrapper>
                        <LinkButton to={"/Profile"}>
                            <ButtonImg src={Logo} alt="Logo"/>
                        </LinkButton>
                    </ButtonWrapper>) : null}
                    {isAdmin ? (<ButtonWrapper>
                        <LinkButton to={"/DBA"} >
                            DBA
                        </LinkButton>
                    </ButtonWrapper>) : null}
                </NavigationMenuWrapper>
            </RigthSideSidebar>
        </SidebarWrapper>
        </>
    )
}

export default Sidebar;