import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Dialog from '../dialog/index.js';

import Sidebar from '../sidebar/index.js';
import {SidebarContainer,ViewContainer,HeaderBar,ProfileContainer,ProfileWrapper,AnagraphicContainer,
  AnagraphicName,AnagraphicWebsite,DetailAttr,DetailName,DetailsElementWrapper,
  LogoContainer,LogoSrc,ModelsAmount,ProfileDetailsContainer,ProfileDetailsWrapper,
  SubsrcripionType,AchievementsCenterContainer,AchievementsContainer,AchievementsDescription,
  AchievementsElement,AchievementsHeader,AchievementsIcon,AchievementsIconContainer,AchievementsName,
  AchievementsValueContainer,CardContainer,CardIcon,CardName,CardValue,CardWrapper,LeftContainer,
  PremiumTag,ProfileEditButton,ProfileHeader,ProfileIcon,ProfileIconContainer,ProfileInfoContainer,
  ProfileInfoFirstRow,ProfileInfoName,ProfileInfoSecondRow,ProfileMetaBall,RightContainer,
  AchievementsValue,ProfileStatsContainer,ProfileWebsite,LogoutWrapper,EditIcon, InputEditProfile} from './profileElements.js';
import useAuth from '../../hooks/useAuth.js';
import useLogout from '../../hooks/useLogout.js';
import UploadLogoForm from '../uploadlogoform/index.js';
import { ChromePicker } from "react-color";

import achievements36icon from '../../img/achievements36icon.png'
import achievements40icon from '../../img/achievements40icon.png'
import models36icon from '../../img/models36icon.png'
import views36icon from '../../img/views36icon.png'
import cover from '../../img/coverprofile.png'
import metaball from '../../img/gifmetaball.gif'
import editicon from '../../img/rename32icon.png'


const Profile = (props) => {

  // potrei farmi passare i dati aggiornati quando faccio un update.

    console.debug("props:" + props)

    const { auth } = useAuth();

    const logoURL = 'https://test.3danimate.it/logos/';
    let Logo = logoURL + auth.id + '/logo';

    //const apiURL = "https://test2.3dabackend.com";
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}`;

    const [isEditable, setIsEditable] = useState(false);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState("");
    const [image, setImage] = useState('');
    const [modelNumber, setModelNumber] = useState(0);
    const [isImageEditable, setIsImageEditable] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdatingInfo, setIsUpdatingInfo] = useState(false);
    const [subscription, setSubscription] = useState('');
    const [maxviewsmodel, setMaxviewsmodel] = useState(false)
    const [maxarviewsmodel, setMaxarviewsmodel] = useState(false)
    const [maxvrviewsmodel, setMaxvrviewsmodel] = useState(false)
    const [maxinteractionsmodel, setMaxinteractionsmodel] = useState(false)
    const [totalinteractions, setTotalInteractions] = useState(false)
    const [totalviews, setTotalViews] = useState(false)
    const [totalarviews, setTotalarviews] = useState(false)
    const [totalvrviews, setTotalvrviews] = useState(false)
    const [objectivesLoaded, setObjectivesLoaded] = useState(false)
    const [completedObjectives, setCompletedObjectives] = useState(0)
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
    const [linkCollection, setLinkCollection] = useState('');

    const handleEdit = () => {
        setIsEditable(!isEditable);
        setShowColorPicker(false);
    };
    
    const logout = useLogout();


    const fetchData = async () => {
      setIsUpdatingInfo(true);
      console.debug("fetching user data ")
      try{
          console.debug("auth: ", auth);
          const id = auth.id;
          const response = await axios.post(`${apiURL}/forum/users/find`, { idUtente: id } , {
          //const response = await axios.post(apiURL + "/forum/user/find", { idUtente: id } ,{
                  headers: {
                  'Authorization': `Bearer ${auth?.accessToken}`,
                  'id' : auth.id,
                  'Content-Type': 'application/json',
                }
              }
          );

          if ( response.data?.success){
            console.debug("success: ", response);
            console.debug(response.data?.message);
            //const fecthedData = response.data.message;
            const fecthedData = response.data.data;
            setName(fecthedData.anagrafica)
            setColor(fecthedData.coloreUtente)
            setUrl(fecthedData.urlUtente)
            setModelNumber(fecthedData.modelli.length)
            setLinkCollection(fecthedData.linkCollection)
            setIsUpdatingInfo(false);

            if(auth?.role === 1){
              setSubscription('Standard')
            } else if(auth?.role === 2){
              setSubscription('Premium')
            }
            
          } else {
            console.debug("not success: ", response);
            setIsUpdatingInfo(true);
          }

      } catch (error) {
          console.error(error);
      }
    };

    useEffect( () => {

      fetchData();

      console.debug("analytics request 2")
      getAnalyticsRequest2()

  }, [auth?.accessToken]);

    const handleSave = async (userId) => {
      setIsUpdatingInfo(true);
      try {
          console.debug("salvo modifiche utente");
          console.debug(userId);
          console.debug(url);
          const response = await axios.post( apiURL + '/forum/users/update', {
            "anagrafica": name,
            "idUtente": userId,
            "urlUtente": url,
            "coloreUtente" : color,
            "linkCollection" : linkCollection
          }, {
          headers: {
              'Authorization': `Bearer ${auth?.accessToken}`,
              'id' : auth.id,
              'Content-Type': 'application/json',
          }
          })
          .then(response => {
            if(response.data.success){
              console.debug("success");
              console.debug(response)
              setIsEditable(false);
              setIsUpdatingInfo(false);
            }
            if(!response.data.success){
              console.debug("not success");
              console.debug(response)
              setIsUpdatingInfo(false);
            } else {
              console.debug("not success and not 200");
              console.debug(response)
              setIsUpdatingInfo(false);
            }
          })
          .catch(error => {
            console.error();
          });
        } catch (error) {
          console.error(error);
      };
    };

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('logo', file);
  
      axios
        .post('/ftp/upload/logo', formData, {
          // in header: id, bearer
          // in body: id_Utente , formData con dentro
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          setImage(response.data.imageUrl);
        })
        .catch((error) => {
          // handle error
        });
    };

    const handleColorChange = (newColor) => {
      setColor(newColor.hex);
    };

    const toggleColorPicker = () => {
      setShowColorPicker(!showColorPicker);
    };

    const enableLogoEdit = (event) => {
      setIsImageEditable(true); 
      setIsUploadSuccessful(false);
    }
    const disableLogoEdit = (event) => {
      setIsImageEditable(false); 
      setIsUploadSuccessful(true);
    }
  
    const handleCancel = () => {
      setIsEditable(false);
    };
  
    const handleConfirm = () => {
      setIsEditable(false);
      handleSave(auth.id);
      // Make API call to update profile using name, url, color values
      // ...
    };

    const handleImageEdit = () => {
      setIsImageEditable(true);
    };
  
    const handleImageCancel = () => {
      setIsImageEditable(false);
    };
  
    const handleImageConfirm = () => {
      setIsImageEditable(false);
      // Make API call to update profile image
      // ...
    };

    const objectives = [
      {
        condition: modelNumber < 5,
        display: `Raggiungi 5 modelli caricati.`,
        description: modelNumber < 5 ? `Carica ${5 - modelNumber} modelli per raggiungere 5 modelli caricati.` : "Obbiettivo raggiunto",
        value: `[ ${modelNumber} / 5 ]`,
        category: 'modelNumber'
      },
      {
        condition: modelNumber < 10,
        display: `Raggiungi 10 modelli caricati.`,
        description: modelNumber < 10 ? `Carica ${10 - modelNumber} modelli per raggiungere 10 modelli caricati.` : "Obbiettivo raggiunto",
        value: `[ ${modelNumber} / 10 ]`,
        category: 'modelNumber'
      },
      {
        condition: modelNumber < 20,
        display: `Raggiungi 20 modelli caricati.`,
        description: modelNumber < 20 ? `Carica ${20 - modelNumber} modelli per raggiungere 20 modelli caricati.` : "Obbiettivo raggiunto",
        value: `[ ${modelNumber} / 20 ]`,
        category: 'modelNumber'
      },
      {
        condition: modelNumber < 40,
        display: `Raggiungi 40 modelli caricati.`,
        description: modelNumber < 40 ? `Carica ${40 - modelNumber} modelli per raggiungere 40 modelli caricati.` : "Obbiettivo raggiunto",
        value: `[ ${modelNumber} / 40 ]`,
        category: 'modelNumber'
      },
      {
        condition: totalviews < 500,
        display: `Raggiungi 500 visualizzazioni totali.`,
        description: totalviews < 500 ? `Ottieni ${500 - totalviews} visualizzazioni per raggiungere 500 visualizzazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalviews} / 500 ]`,
        category: 'totalviews'
      },
      {
        condition: totalviews < 1000,
        display: `Raggiungi 1000 visualizzazioni totali.`,
        description: totalviews < 1000 ? `Ottieni ${1000 - totalviews} visualizzazioni per raggiungere 1000 visualizzazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalviews} / 1000 ]`,
        category: 'totalviews'
      },
      {
        condition: totalviews < 2000,
        display: `Raggiungi 2000 visualizzazioni totali.`,
        description: totalviews < 2000 ? `Ottieni ${2000 - totalviews} visualizzazioni per raggiungere 2000 visualizzazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalviews} / 2000 ]`,
        category: 'totalviews'
      },
      {
        condition: totalviews < 5000,
        display: `Raggiungi 5000 visualizzazioni totali.`,
        description: totalviews < 5000 ? `Ottieni ${5000 - totalviews} visualizzazioni per raggiungere 5000 visualizzazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalviews} / 5000 ]`,
        category: 'totalviews'
      },
      {
        condition: totalvrviews < 50,
        display: `Raggiungi 50 visualizzazioni VR totali.`,
        description: totalvrviews < 50 ? `Ottieni ${50 - totalvrviews} visualizzazioni VR per raggiungere 50 visualizzazioni VR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalvrviews} / 50 ]`,
        category: 'totalvrviews'
      },
      {
        condition: totalvrviews < 100,
        display: `Raggiungi 100 visualizzazioni VR totali.`,
        description: totalvrviews < 100 ? `Ottieni ${100 - totalvrviews} visualizzazioni VR per raggiungere 100 visualizzazioni VR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalvrviews} / 100 ]`,
        category: 'totalvrviews'
      },
      {
        condition: totalvrviews < 200,
        display: `Raggiungi 200 visualizzazioni VR totali.`,
        description: totalvrviews < 200 ? `Ottieni ${200 - totalvrviews} visualizzazioni VR per raggiungere 200 visualizzazioni VR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalvrviews} / 200 ]`,
        category: 'totalvrviews'
      },
      {
        condition: totalvrviews < 500,
        display: `Raggiungi 500 visualizzazioni VR totali.`,
        description: totalvrviews < 500 ? `Ottieni ${500 - totalvrviews} visualizzazioni VR per raggiungere 500 visualizzazioni VR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalvrviews} / 500 ]`,
        category: 'totalvrviews'
      },
      {
        condition: totalarviews < 50,
        display: `Raggiungi 50 visualizzazioni AR totali.`,
        description: totalarviews < 50 ? `Ottieni ${50 - totalarviews} visualizzazioni AR per raggiungere 50 visualizzazioni AR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalarviews} / 50 ]`,
        category: 'totalarviews'
      },
      {
        condition: totalarviews < 100,
        display: `Raggiungi 100 visualizzazioni AR totali.`,
        description: totalarviews < 100 ? `Ottieni ${100 - totalarviews} visualizzazioni AR per raggiungere 100 visualizzazioni AR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalarviews} / 100 ]`,
        category: 'totalarviews'
      },
      {
        condition: totalarviews < 200,
        display: `Raggiungi 200 visualizzazioni AR totali.`,
        description: totalarviews < 200 ? `Ottieni ${200 - totalarviews} visualizzazioni AR per raggiungere 200 visualizzazioni AR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalarviews} / 200 ]`,
        category: 'totalarviews'
      },
      {
        condition: totalarviews < 500,
        display: `Raggiungi 500 visualizzazioni AR totali.`,
        description: totalarviews < 500 ? `Ottieni ${500 - totalarviews} visualizzazioni AR per raggiungere 500 visualizzazioni AR totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalarviews} / 500 ]`,
        category: 'totalarviews'
      },
      {
        condition: totalinteractions < 1000,
        display: `Raggiungi 1000 interazioni totali.`,
        description: totalinteractions < 1000 ? `Ottieni ${1000 - totalinteractions} interazioni per raggiungere 1000 interazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalinteractions} / 1000 ]`,
        category: 'totalinteractions'
      },
      {
        condition: totalinteractions < 2000,
        display: `Raggiungi 2000 interazioni totali.`,
        description: totalinteractions < 2000 ? `Ottieni ${2000 - totalinteractions} interazioni per raggiungere 2000 interazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalinteractions} / 2000 ]`,
        category: 'totalinteractions'
      },
      {
        condition: totalinteractions < 5000,
        display: `Raggiungi 5000 interazioni totali.`,
        description: totalinteractions < 5000 ? `Ottieni ${5000 - totalinteractions} interazioni per raggiungere 5000 interazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalinteractions} / 5000 ]`,
        category: 'totalinteractions'
      },
      {
        condition: totalinteractions < 10000,
        display: `Raggiungi 10000 interazioni totali.`,
        description: totalinteractions < 10000 ? `Ottieni ${10000 - totalinteractions} interazioni per raggiungere 10000 interazioni totali.` : "Obbiettivo raggiunto",
        value: `[ ${totalinteractions} / 10000 ]`,
        category: 'totalinteractions'
      },
      {
        condition: maxviewsmodel < 100,
        display: `Raggiungi 100 visualizzazioni su un modello.`,
        description: maxviewsmodel < 100 ? `Ottieni ${100 - maxviewsmodel} visualizzazioni per raggiungere 100 visualizzazioni su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxviewsmodel} / 100 ]`,
        category: 'maxviewsmodel'
      },
      {
        condition: maxviewsmodel < 200,
        display: `Raggiungi 200 visualizzazioni su un modello.`,
        description: maxviewsmodel < 200 ? `Ottieni ${200 - maxviewsmodel} visualizzazioni per raggiungere 200 visualizzazioni su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxviewsmodel} / 200 ]`,
        category: 'maxviewsmodel'
      },
      {
        condition: maxviewsmodel < 500,
        display: `Raggiungi 500 visualizzazioni su un modello.`,
        description: maxviewsmodel < 500 ? `Ottieni ${500 - maxviewsmodel} visualizzazioni per raggiungere 500 visualizzazioni su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxviewsmodel} / 500 ]`,
        category: 'maxviewsmodel'
      },
      {
        condition: maxviewsmodel < 1000,
        display: `Raggiungi 1000 visualizzazioni su un modello.`,
        description: maxviewsmodel < 1000 ? `Ottieni ${1000 - maxviewsmodel} visualizzazioni per raggiungere 1000 visualizzazioni su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxviewsmodel} / 1000 ]`,
        category: 'maxviewsmodel'
      },
      {
        condition: maxvrviewsmodel < 5,
        display: `Raggiungi 5 visualizzazioni VR su un modello.`,
        description: maxvrviewsmodel < 5 ? `Ottieni ${5 - maxvrviewsmodel} visualizzazioni VR per raggiungere 5 visualizzazioni VR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxvrviewsmodel} / 5 ]`,
        category: 'maxvrviewsmodel'
      },
      {
        condition: maxvrviewsmodel < 10,
        display: `Raggiungi 10 visualizzazioni VR su un modello.`,
        description: maxvrviewsmodel < 10 ? `Ottieni ${10 - maxvrviewsmodel} visualizzazioni VR per raggiungere 10 visualizzazioni VR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxvrviewsmodel} / 10 ]`,
        category: 'maxvrviewsmodel'
      },
      {
        condition: maxvrviewsmodel < 20,
        display: `Raggiungi 20 visualizzazioni VR su un modello.`,
        description: maxvrviewsmodel < 20 ? `Ottieni ${20 - maxvrviewsmodel} visualizzazioni VR per raggiungere 20 visualizzazioni VR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxvrviewsmodel} / 20 ]`,
        category: 'maxvrviewsmodel'
      },
      {
        condition: maxvrviewsmodel < 50,
        display: `Raggiungi 50 visualizzazioni VR su un modello.`,
        description: maxvrviewsmodel < 50 ? `Ottieni ${50 - maxvrviewsmodel} visualizzazioni VR per raggiungere 50 visualizzazioni VR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxvrviewsmodel} / 50 ]`,
        category: 'maxvrviewsmodel'
      },
      {
        condition: maxarviewsmodel < 5,
        display: `Raggiungi 5 visualizzazioni AR su un modello.`,
        description: maxarviewsmodel < 5 ? `Ottieni ${5 - maxarviewsmodel} visualizzazioni AR per raggiungere 5 visualizzazioni AR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxarviewsmodel} / 5 ]`,
        category: 'maxarviewsmodel'
      },
      {
        condition: maxarviewsmodel < 10,
        display: `Raggiungi 10 visualizzazioni AR su un modello.`,
        description: maxarviewsmodel < 10 ? `Ottieni ${10 - maxarviewsmodel} visualizzazioni AR per raggiungere 10 visualizzazioni AR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxarviewsmodel} / 10 ]`,
        category: 'maxarviewsmodel'
      },
      {
        condition: maxarviewsmodel < 20,
        display: `Raggiungi 20 visualizzazioni AR su un modello.`,
        description: maxarviewsmodel < 20 ? `Ottieni ${20 - maxarviewsmodel} visualizzazioni AR per raggiungere 20 visualizzazioni AR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxarviewsmodel} / 20 ]`,
        category: 'maxarviewsmodel'
      },
      {
        condition: maxarviewsmodel < 50,
        display: `Raggiungi 50 visualizzazioni AR su un modello.`,
        description: maxarviewsmodel < 50 ? `Ottieni ${50 - maxarviewsmodel} visualizzazioni AR per raggiungere 50 visualizzazioni AR su un singolo modello.` : "Obbiettivo raggiunto",
        value: `[ ${maxarviewsmodel} / 50 ]`,
        category: 'maxarviewsmodel'
      }
    ];
    


    /* 
    *
    *
    * Obbiettivi Gamification:
    - n° modelli caricati ( 5/10/20/40 )
    - n° visualizzazioni totali ( 500/1000/2000/5000 )
    - raggiungi X visualizzazioni su un modello ( 100/200/500/1000 )
    - n° visualizzazioni AR totali ( 50/100/200/500 )
    - raggiungi X visualizzazioni AR su un modello ( 5/10/20/50 )
    - n° visualizzazioni VR totali ( 50/100/200/500 )
    - raggiungi X visualizzazioni VR su un modello ( 5/10/20/50 )
    - n° interazioni totali ( 1000/2000/5000/10000 )
    - raggiungi X interazioni su un modello ( 100/200/500/1000 )
    * 
    * 
    * /analytics - analytics per i modelli dell'utente di cui sto chiedendo.

      /model/avg - è una richiesta delle medie di un modello

      /model/sum - è una richiesta dei totali di un modello

      /session/sum - è una richiesta dei totali in un range di tempo di un modello

      /session/avg - è una richiesta delle medie in un range di tempo di un modello

      /session - è l'inserimento vero?

      /model - è un altro inserimento ma che riguarda i campi che mi hai scritto, no?
    * 
    * 
    * 
    * 
    * 
    * 
    * */
    // Function to make the Axios request
    async function getAnalyticsRequest2() {
      const url = apiURL + '/tacito/analytics'; 
  
      try {
        const now = new Date();
        const formattedNow = now.toISOString();
      const response = await axios.post(url, {
          id_utente: auth.id,
          start_date: '2023-06-01T12:54:13.927Z',
          end_date: formattedNow,
          fields:{
            start_date:"2023-06-01T12:54:13.927Z",
            end_date:formattedNow,
            id_utente: auth.id
          }
      },{
        headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id,
        'ngrok-skip-browser-warning': '69420',
       }
      });
  
      console.debug('Analytics /tacito/analytic response:', response);
      console.debug(response.data.data)
      console.debug(response.data.data.max_interactions_model.total)
      console.debug(response.data.data.max_views_model.total)
      console.debug(response.data.data.max_views_model_ar.total)
      console.debug(response.data.data.max_views_model_vr.total)
      console.debug(response.data.data.vis_tot.sum_interactions)
      console.debug(response.data.data.vis_tot.sum_views)
      console.debug(response.data.data.vis_tot.sum_views_ar)
      console.debug(response.data.data.vis_tot.sum_views_vr)

      setMaxinteractionsmodel(response.data.data.max_interactions_model.total)
      setMaxviewsmodel(response.data.data.max_views_model.total)
      setMaxarviewsmodel(response.data.data.max_views_model_ar.total)
      setMaxvrviewsmodel(response.data.data.max_views_model_vr.total)
      setTotalInteractions(response.data.data.vis_tot.sum_interactions)
      setTotalViews(response.data.data.vis_tot.sum_views)
      setTotalarviews(response.data.data.vis_tot.sum_views_ar)
      setTotalvrviews(response.data.data.vis_tot.sum_views_vr)

      setCompletedObjectives(objectives.filter(objective => !objective.condition).length)

      setObjectivesLoaded(true)

    } catch (error) {
      console.error('Error sending analytics request:', error); // Handle any errors that occur
      }
  }

  // Function to make the Axios request
  async function getAnalyticsModelAvgRequest() {
    const url = apiURL + '/tacito/model/avg'; 

    try {
    const response = await axios.post(url, {
      start_date:"2023-06-01T12:54:13.927Z",
      end_date:"2023-07-03T12:54:13.927Z",
      id_modello:77867,
      fields:{
        start_date:"2023-06-01T12:54:13.927Z",
        end_date:"2023-07-03T12:54:13.927Z",
        id_modello:77867
      }
    },{
    headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id' : auth.id, }
    });

    console.debug('model/avg response:', response.data); // Handle the response data accordingly
    } catch (error) {
    console.error('Error sending analytics request:', error); // Handle any errors that occur
    }
}

// Function to make the Axios request
async function getAnalyticsModelSumRequest() {
  const url = apiURL + '/tacito/model/sum';

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/model/sum response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function getAnalyticsSessionAvgRequest() {
  const url = apiURL + '/tacito/session/avg'; 

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/session/avg response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function getAnalyticsSessionSumRequest() {
  const url = apiURL + '/tacito/session/sum'; 

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/session/sum response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function sendAnalyticsModel() {
  const url = apiURL + '/tacito/model'; 

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/model response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function sendAnalyticsSession() {
  const url = apiURL + '/tacito/session'; 

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/session response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function sendAnalyticsSession() {
  const url = apiURL + '/tacito/rewards'; // Replace with your actual backend URL

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/rewards response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function sendAnalyticsSession() {
  const url = apiURL + '/tacito/rewards/user'; // Replace with your actual backend URL

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/rewards/user response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

// Function to make the Axios request
async function sendAnalyticsSession() {
  const url = apiURL + '/tacito/rewards/model'; // Replace with your actual backend URL

  try {
  const response = await axios.post(url, {
      id_utente: 1, // Replace `auth.id` with your actual user ID
      start_date: '2023-01-01', // Replace with your desired start date
      end_date: '2023-12-31', // Replace with your desired end date
  });

  console.debug('Analytics /tacito/rewards/model response:', response.data); // Handle the response data accordingly
  } catch (error) {
  console.error('Error sending analytics request:', error); // Handle any errors that occur
  }
}

const id_utente = "1";
const baseURL = "'https://test.3danimate.it/"; // Replace with your API endpoint

function logResponse(response) {
  console.debug(response.data);
}

function handleError(error) {
  console.error(error);
}

async function getModelSumRequest() {
  const url = apiURL + '/tacito/model/sum';

  try {
    const response = await axios.post(url, {
      start_date: "2023-06-01T12:54:13.927Z",
      end_date: "2023-07-03T12:54:13.927Z",
      fields: {id_modello:"77867"}
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Model Sum response:', response.data);
  } catch (error) {
    console.error('Error sending Model Sum request:', error);
  }
}

async function getModelAvgRequest() {
  const url = apiURL + '/tacito/model/avg';

  try {
    const response = await axios.post(url, {
      id_modello: "your_model_id",
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Model Average response:', response.data);
  } catch (error) {
    console.error('Error sending Model Average request:', error);
  }
}

async function getModelsRequest() {
  const url = apiURL + '/tacito/model/';

  try {
    const response = await axios.post(url, {
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Models response:', response.data);
  } catch (error) {
    console.error('Error sending Models request:', error);
  }
}

async function getSessionSumRequest() {
  const url = apiURL + '/tacito/session/sum';

  try {
    const response = await axios.post(url, {
      id_sessione: "your_session_id",
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Session Sum response:', response.data);
  } catch (error) {
    console.error('Error sending Session Sum request:', error);
  }
}

async function getSessionAvgRequest() {
  const url = apiURL + '/tacito/session/avg';

  try {
    const response = await axios.post(url, {
      id_sessione: "your_session_id",
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Session Average response:', response.data);
  } catch (error) {
    console.error('Error sending Session Average request:', error);
  }
}

async function getSessionsRequest() {
  const url = apiURL + '/tacito/session/';

  try {
    const response = await axios.post(url, {
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    });

    console.debug('Sessions response:', response.data);
  } catch (error) {
    console.error('Error sending Sessions request:', error);
  }
}

async function putSession() {
  const url = apiURL + '/tacito/session/';

  try {
    const response = await axios.post(url, {
      "id_sessione": 12332131203123123,
      "id_modello": 22,
      "id_utente": 1,
      "elapsed_time":123125531.2323
    });

    console.debug('Sessions response:', response.data);
  } catch (error) {
    console.error('Error sending Sessions request:', error);
  }
}

async function getAnalyticsRequest() {
  const url = apiURL + '/tacito/analytics';

  try {
    const response = await axios.post(url, {
      id_modello: "your_model_id",
      id_utente: 1, // Replace `auth.id` with your actual user ID
      id_sessione: "your_session_id",
      start_date: "2023-01-01",
      end_date: "2023-12-31",
      fields:{
        start_date:"2023-06-01T12:54:13.927Z",
        end_date:"2023-07-03T12:54:13.927Z",
        id_utente: 93
      }
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Analytics response:', response.data);
  } catch (error) {
    console.error('Error sending Analytics request:', error);
  }
}

async function postRewardsRequest() {
  const url = apiURL + '/tacito/rewards';

  try {
    const response = await axios.post(url, {
      rewards: [
        { name: "reward1", type: "type1", threshold: 10 },
        { name: "reward2", type: "type2", threshold: 20 }
      ]
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Rewards response:', response.data);
  } catch (error) {
    console.error('Error sending Rewards request:', error);
  }
}

async function getUserRewardsRequest() {
  const url = apiURL + '/tacito/rewards/user';

  try {
    const response = await axios.post(url, {
      id_utente: 1, // Replace with the desired user ID
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('User Rewards response:', response.data);
  } catch (error) {
    console.error('Error sending User Rewards request:', error);
  }
}

async function getModelRewardsRequest() {
  const url = apiURL + '/tacito/rewards/model';

  try {
    const response = await axios.post(url, {
      id_modello: "your_model_id",
      start_date: "2023-01-01",
      end_date: "2023-12-31",
    }, {
      headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
    });

    console.debug('Model Rewards response:', response.data);
  } catch (error) {
    console.error('Error sending Model Rewards request:', error);
  }
}


    return(
        <>
        <SidebarContainer>
            <Sidebar bgColor={'#1E293B'}/>
        </SidebarContainer>
        <ViewContainer>
          <ProfileWrapper>
            <ProfileContainer>
              <LeftContainer>
                <ProfileHeader cover={cover}>
                  <ProfileIconContainer>
                    <ProfileMetaBall metaball={metaball}>
                      <ProfileIcon src={Logo} />
                      {/*<EditIcon src={editicon} onClick={handleImageEdit}/>*/}
                    </ProfileMetaBall>
                  </ProfileIconContainer>
                  <ProfileEditButton onClick={handleEdit}>Modifica Profilo</ProfileEditButton>

                  {isEditable && (
                    <Dialog onConfirm={handleConfirm} onCancel={handleCancel} title={"Modifica i campi"}>
                      <UploadLogoForm dbaSelectedUser={auth.id} onSuccess={() => setIsUploadSuccessful(true)} />
                      <AnagraphicContainer>
                        Nome
                        <InputEditProfile type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        Indirizzo URL
                        <InputEditProfile type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
                        Colore
                        <InputEditProfile type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                      </AnagraphicContainer>
                    </Dialog>
                  )}

                  {isImageEditable && (
                          <Dialog onConfirm={handleImageConfirm} onCancel={handleImageCancel}>
                            {/* Dialog Content for image upload */}
                            {/* ... */}
                            <UploadLogoForm dbaSelectedUser={auth.id} onSuccess={() => setIsUploadSuccessful(true)} ></UploadLogoForm>
                          </Dialog>
                  )}

                </ProfileHeader>
                <ProfileInfoContainer>
                  <ProfileInfoFirstRow>
                    <ProfileInfoName>{name}</ProfileInfoName>
                    {auth.role === '2' && (
                      <PremiumTag>Premium</PremiumTag>
                    )}
                    <LogoutWrapper onClick={logout} >Logout</LogoutWrapper>
                  </ProfileInfoFirstRow>
                  <ProfileInfoSecondRow>
                    <ProfileWebsite>{url}</ProfileWebsite>                    
                  </ProfileInfoSecondRow>
                </ProfileInfoContainer>
                <ProfileStatsContainer>
                  <CardContainer>
                    <CardWrapper>
                      <CardIcon src={models36icon} />
                      <CardName>Modelli Caricati</CardName>
                      <CardValue>{modelNumber}</CardValue>
                    </CardWrapper>
                  </CardContainer>
                  <CardContainer>
                    <CardWrapper>
                      <CardIcon src={achievements36icon} />
                      <CardName>Obbiettivi Sbloccati</CardName>
                      <CardValue>{completedObjectives}</CardValue>
                    </CardWrapper>
                  </CardContainer>
                  <CardContainer>
                    <CardWrapper>
                      <CardIcon src={views36icon} />
                      <CardName>Visualizzazioni Totali</CardName>
                      <CardValue>{totalviews}</CardValue>
                    </CardWrapper>
                  </CardContainer>
                </ProfileStatsContainer>
              </LeftContainer>
              <RightContainer>
              <AchievementsContainer>
                <AchievementsHeader>Obbiettivi</AchievementsHeader>
                {(() => {
                  const categories = [...new Set(objectives.map(obj => obj.category))];
                  let toDisplay = [];

                  categories.forEach(category => {
                    const categoryObjectives = objectives.filter(obj => obj.category === category);
                    const unmetObjectives = categoryObjectives.filter(obj => obj.condition);

                    // Add the first unmet objective, if any
                    if (unmetObjectives.length > 0) {
                      toDisplay.push(unmetObjectives[0]);
                    }

                    // Find the last met objective and add it with the description "Obbiettivo raggiunto"
                    for (let i = categoryObjectives.length - 1; i >= 0; i--) {
                      if (!categoryObjectives[i].condition) {
                        const lastMetObjective = {...categoryObjectives[i]};
                        lastMetObjective.description = "Obbiettivo raggiunto";
                        toDisplay.push(lastMetObjective);
                        break;
                      }
                    }
                  });

                  return toDisplay.map((objective, index) => (
                    <AchievementsElement key={index}>
                      <AchievementsIconContainer>
                        <AchievementsIcon src={achievements40icon}/>
                      </AchievementsIconContainer>
                      <AchievementsCenterContainer>
                        <AchievementsName>
                          {objective.display}
                        </AchievementsName>
                        <AchievementsDescription>
                          {objective.description}
                        </AchievementsDescription>
                      </AchievementsCenterContainer>
                      <AchievementsValueContainer>
                        { 
                          // Check if there's an unmet objective in the same category
                          !objective.condition && toDisplay.some(obj => obj.category === objective.category && obj.condition) 
                          ? null 
                          : <AchievementsValue>
                              {objective.value}
                            </AchievementsValue>
                        }
                      </AchievementsValueContainer>
                    </AchievementsElement>
                  ));
                })()}
              </AchievementsContainer>     
              </RightContainer>
          </ProfileContainer>
        </ProfileWrapper>
        </ViewContainer>
        </>
    )
}

export default Profile;