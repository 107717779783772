import React, { useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const Redirects = () => {
    console.log("redirecting")
    const [searchParams] = useSearchParams();
    const id_utente = searchParams.get('id_utente');
    const id_modello = searchParams.get('id_modello');
    const other_code = searchParams.get('other_code');
    const ARorVR = searchParams.get('ARorVR');    
    const vr_quality = searchParams.get('vr_quality');

    useEffect(() => {
        let views_ar = 0;
        let views_vr = 0;
        let redirectSuffix = '';

        if (ARorVR === 'AR') {
            views_ar = 1;
            redirectSuffix = '/ar-redirect';
        } else if (ARorVR === 'VR') {
            views_vr = 1;

            switch (vr_quality) {
                case 'low':
                    redirectSuffix = '/embed?vr_quality=1.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1';
                    break;
                case 'medium':
                    redirectSuffix = '/embed?vr_quality=2.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1';
                    break;
                case 'high':
                    redirectSuffix = '/embed?vr_quality=3.5&pratio=2.5&vr_scale_rtt=1&autostart=1&vr=1&maxDevicePixelRatio=1';
                    break;
                default:
                    redirectSuffix = '/embed?autostart=1&cardboard=1&internal=1&tracking=0&ui_infos=0&ui_snapshots=1&ui_stop=0&ui_theatre=1&ui_watermark=0';
                    break;
            }
        }

        // Make the POST request
        axios.post('https://test2.3dabackend.com/tacito/model', {
            "id_sessione": Date.now(),
            "id_modello": id_modello,
            "id_utente": Number(id_utente),
            "views": 1,
            "views_ar": views_ar,
            "views_vr": views_vr,
            "interactions" : 1,
            //"load_time" : 0,
            "clicks": 1
        }, {
            headers: { 
                //'Authorization': `Bearer ${auth?.accessToken}`, 
                'id': Number(id_utente) ,
                'is-internal': "true"
            }
        })
        .then(response => {
            console.log('Session response:', response.data);
        })
        .catch(error => {
            console.error('Error sending /tacito/model request:', error);
        });

        // Redirect immediately
        const redirectUrl = `https://sketchfab.com/models/${other_code}${redirectSuffix}`;
        //window.location.href = redirectUrl;
        console.log("redirecting to:", redirectUrl);
    }, [id_utente, id_modello, other_code, ARorVR]);

    return (<>redirecting</>)
};

export default Redirects;

/*
const qrARCodeUrl = "https://sketchfab.com/models/" + modelInfo.uid + "/ar-redirect";
const qrVRCodeUrl = "https://sketchfab.com/models/" + modelInfo.uid + "/embed?autostart=1&cardboard=1&internal=1&tracking=0&ui_infos=0&ui_snapshots=1&ui_stop=0&ui_theatre=1&ui_watermark=0";
*/