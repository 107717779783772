import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';

import axios from 'axios';
import useAuth from '../../hooks/useAuth.js';

import { SidebarContainer, ViewContainer, HeaderBarContainer, FirstSection, SecondSection, 
    SmallPanel, HeaderBarWrapper,
    PanelTitle, PanelContent, HeaderTabsContainer, HeaderTabElement, HeaderTitle, BodyContainer, 
    TwoThirdColumn, LargePanel, LargestPanel, OneThirdColumn, HalfColumn, ColumnPanel,
    SmallestPanel, RowContainer, ColumnContainer,PanelWrapper,DescriptionContainer,
    IconContainer,ValueContainer, LargePanelDescriptionm, LargePanelDescription,
    LargePanelName, LargePanelValueName,LargePanelValueNumber,LargePanelValueRow,LeftPanelColumn,
    RightPanelColumn, LargeImagePanel,LargePanelWrapper, ModelBoxContainer,AnalyticsDetailContainer,
    DetailFirstSectionContainer,SecondSectionWrapper,SketchFabFrameContainer,DetailSecondSectionContainer,
    HeatmapButtonContainer,HeatmapContainer,HeatmapToggle,HeatmapWrapper,ThumbnailContent,ViewsChartContainer,
BackButton,BackButtonIcon,ContentWrapper,CenterWrapper,CenterContainer,PanelLineContent } from '../analytics/analyticsElements.js';

import backicon from '../../img/back24icon.png'
import analyticsaricon from '../../img/analyticsar36icon.png'
import analyticsvricon from '../../img/analyticsvr36icon.png'
import analyticsinteractionicon from '../../img/analyticsinteraction36icon.png'
import analyticstimeicon from '../../img/analyticstime36icon.png'
import analyticsviewsicon from '../../img/analyticsviews36icon.png' 
import HeatmapView from '../heatmapview/index.js';

let cancelTokenHeatmapSource;

const AnalyticsDetail = (props) => {
    // const apiUrl = 'https://test2.3dabackend.com';
    // const apiUrl = 'https://0146-87-17-214-243.ngrok-free.app'
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

    const [isLoading, setIsLoading] = useState(true);
    const { auth } = useAuth();
    const [selectedModel, setSelectedModel] = useState(props.selectedModel);
    
    const [singlemodelSumElapsedTime, setSinglemodelSumElapsedTime] = useState(0);
    const [singlemodelAvgElapsedTime, setSinglemodelAvgElapsedTime] = useState(0);
    const [singlemodelInteractions, setSinglemodelInteractions] = useState(0);
    const [singlemodelViews, setSinglemodelViews] = useState(0);
    const [singlemodelViewsAr, setSinglemodelViewsAr] = useState(0);
    const [singlemodelViewsVr, setSinglemodelViewsVr] = useState(0);
    const [singlemodelAvgLoadTime, setSinglemodelAvgLoadTime] = useState(0);
    const [singlemodelAvgInteractions, setSinglemodelAvgInteractions] = useState(0);
    const [noAnalytics, setNoAnalytics] = useState(false);

    const analyticsDetailContainerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    const SmallestPanelData = [
        { icon: analyticsviewsicon, description: "Visualizzazioni Totali", value: singlemodelViews || 0 },
        { icon: analyticsaricon, description: "Visualizzazioni Totali AR", value: singlemodelViewsAr || 0 },
        { icon: analyticsvricon, description: "Visualizzazioni Totali VR", value: singlemodelViewsVr || 0 },
        { icon: analyticstimeicon, description: "Tempo medio di caricamento", value: (singlemodelAvgLoadTime/1000).toFixed(2) + " s" || 0 },
        { icon: analyticsinteractionicon, description: "Interazioni Totali", value: singlemodelInteractions || 0 },
        { icon: analyticsinteractionicon, description: "Interazioni medie", value: (singlemodelInteractions/singlemodelViews).toFixed(2) || 0 },
        { icon: analyticstimeicon, description: "Tempo totale di visualizzazione", value: ((singlemodelSumElapsedTime / 1000).toFixed(2)) + " s" || 0 },
        { icon: analyticstimeicon, description: "Tempo medio di visualizzazione", value: (((singlemodelSumElapsedTime/singlemodelViews)/1000).toFixed(2)) + " s" || 0 }
    ];
    const heatmapRef = useRef(null);
    const parentRef = useRef(null);
    const [opacity, setOpacity] = useState(1); 
    const [heatmapVisible, setHeatmapVisible] = useState(true);
    const [heatmapData, setHeatmapData] = useState(null);

    const [modelchartBarLoaded, setModelChartBarLoaded] = useState(false);
    const [modelchartData, setModelChartData] = useState(null);
    const [modelchartOptions, setModelChartOptions] = useState(null);

    console.debug('AnalyticsDetail props: ', props);

    const fetchMonthlyModelViews = async (selectedModelId) => {
        const monthlyModelViews = [];
        const startDate = new Date(props.startDate);
        const endDate = new Date(props.endDate);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();

        let currentYear = startYear;
        let currentMonth = startMonth;

        while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
            const firstDayOfMonth = new Date(currentYear, currentMonth, 1).toISOString();
            const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).toISOString();

            try {
                const response = await axios.post(apiUrl + "/tacito/model/sum", {
                    // id_utente: auth.id,
                    start_date: firstDayOfMonth,
                    end_date: lastDayOfMonth,
                    // id_modello: props.data.sortedModels[selectedModel]._id,
                    fields: {
                        id_modello: props.data.sortedModels[selectedModel]._id
                    }
                }, {
                    headers: {
                        'Authorization': `Bearer ${auth?.accessToken}`,
                        'id': auth.id,
                    }
                });

                console.debug(`/tacito/model/sum singolo mese ${currentMonth}: `, response.data);

                if (response.data && response.data.data) {
                    monthlyModelViews.push(response.data.data?.sum_views);
                } else {
                    monthlyModelViews.push(0);
                }
            } catch (error) {
                if (error.response && error.response.status !== 404) {
                    //console.error(`A: Error fetching views for month ${currentMonth + 1} for model ${selectedModelId}:`, error);
                }
                monthlyModelViews.push(0);
            }

            if (currentMonth === 11) {
                currentYear++;
                currentMonth = 0;
            } else {
                currentMonth++;
            }
        }
        console.log("monthlyModelViews sum: "+monthlyModelViews.reduce((sum, value) => sum + value, 0));
        return monthlyModelViews;
    }

    const sendAnalyticsModelAvgRequest = async () => {
        if (selectedModel < 0 || selectedModel === null) {
            console.debug("selectedModel inside analyticsdetail: " + selectedModel);
            console.debug("selectedModel is null or undefined.");
            return;
        }
        const url = apiUrl + '/tacito/model/avg';
        const now = new Date();
        const formattedNow = now.toISOString();

        try {
            console.debug("id modello attuale")
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/model/avg', {
                start_date:props.startDate,
                end_date:props.endDate,
                fields:{
                    id_modello:props.data.sortedModels[selectedModel]._id
                }
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
              });
        
            console.debug('/model/avg response:', response.data); 
            setSinglemodelAvgInteractions(response.data.data.avg_int.toFixed(1) || "not available")
            setSinglemodelAvgLoadTime(response.data.data.avg_lt.toFixed(1) || "not available")
            } catch (error) {
            console.error('B: Error sending analytics request:', error); // Handle any errors that occur
            setSinglemodelAvgInteractions( "not available")
            setSinglemodelAvgLoadTime( "not available")
            }

        try {
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/model/sum', {
                start_date:props.startDate,
                end_date:props.endDate,
                fields:{
                    id_modello:props.data.sortedModels[selectedModel]._id
                }
                
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
            });
        
            console.debug('/model/sum response totale:', response.data);
            setSinglemodelInteractions(response.data.data?.sum_interactions)
            setSinglemodelViews(response.data.data?.sum_views)
            setSinglemodelViewsAr(response.data.data?.sum_views_ar)
            setSinglemodelViewsVr(response.data.data?.sum_views_vr)

            } catch (error) {
            console.error('C: Error sending analytics request:', error); // Handle any errors that occur
            setSinglemodelInteractions(0)
            setSinglemodelViews(0)
            setSinglemodelViewsAr(0)
            setSinglemodelViewsVr(0)
            }

        try {
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/session/sum', {
                start_date:props.startDate,
                end_date:props.endDate,
                // id_modello:props.data.sortedModels[selectedModel]._id,
                fields:{
                    id_modello:props.data.sortedModels[selectedModel]._id
                }
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
              });
        
            // !! ERA sum_elapsed_time DA RICONFRONTARE con sum_view_time !!

            console.debug('/session/sum response:', response.data);
            setSinglemodelSumElapsedTime(response.data.data?.sum_view_time)
            } catch (error) {
                setSinglemodelSumElapsedTime(0)
            console.error('D: Error sending analytics request:', error); // Handle any errors that occur
            }

            
        try {
            const now = new Date();
            const formattedNow = now.toISOString();
            const response = await axios.post(apiUrl + '/tacito/session/avg', {
                start_date:props.startDate,
                end_date:props.endDate,
                // id_modello:props.data.sortedModels[selectedModel]._id,
                fields:{
                    id_modello:props.data.sortedModels[selectedModel]._id
                }  
            }, {
                headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
            });
            console.debug('/session/avg response totale:', response.data);
            setSinglemodelAvgElapsedTime(response.data.data?.avg_view_time)
            } catch (error) {
            console.error('E: Error sending analytics request:', error); // Handle any errors that occur
            setSinglemodelAvgElapsedTime(0)
            }

            try {
                const now = new Date();
                const formattedNow = now.toISOString();
                const response = await axios.post(apiUrl + '/tacito/heatmap/map', {
                    start_date:props.startDate,
                    end_date:props.endDate,
                    // id_modello:props.data.sortedModels[selectedModel]._id,
                fields:{
                    id_modello:props.data.sortedModels[selectedModel]._id
                }
                }, {
                    headers: { 'Authorization': `Bearer ${auth?.accessToken}`, 'id': auth.id }
                });
                console.debug('/heatmap/map response:', response.data.data); 
                console.debug("/heatmap/map selectedModel._id", props.data.sortedModels[selectedModel]._id);
                console.debug(props.data.sortedModels[selectedModel]._id);
                setHeatmapData(response.data.data?.[0]);
                console.debug("heatmapData response");
                console.debug(heatmapData);
            } catch (error) {
                console.error('F: Error sending analytics request:', error); // Handle any errors that occur
            }
            setIsLoading(false)
    }

    const getModelChartData = async () => {
        console.debug("get model char data")
        const monthlyData = await fetchMonthlyModelViews();
    
        const data = {
            labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            datasets: [
                {
                    label: 'Visualizzazioni per mese',
                    data: monthlyData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            maintainAspectRatio: false
        };
    
        setModelChartData(data);
        setModelChartOptions(options);
        setModelChartBarLoaded(true)
    }

    const RenderSmallestPanel = ({ icon, description, value }) => (
        <SmallestPanel>
            <PanelWrapper>
                <PanelContent>
                    <IconContainer src={icon} />
                    <DescriptionContainer>{description}</DescriptionContainer>
                    <ValueContainer>{value}</ValueContainer>
                </PanelContent>
            </PanelWrapper>
        </SmallestPanel>
    )

    useEffect(() => {
        if (analyticsDetailContainerRef.current) {
          const resizeObserver = new ResizeObserver(entries => {
            // Assuming you're only observing one element
            const newHeight = entries[0].contentRect.height;
            setContainerHeight(newHeight);
          });
      
          // Start observing the element
          resizeObserver.observe(analyticsDetailContainerRef.current);
      
          // Clean up the observer on component unmount
          return () => resizeObserver.disconnect();
        }
      }, []);      

    useEffect(() => {
        sendAnalyticsModelAvgRequest();
        getModelChartData();
    }, [auth.id, props.startDate, props.endDate])

    return(
        <>
        
        <CenterWrapper>

            <CenterContainer>
                <AnalyticsDetailContainer ref={analyticsDetailContainerRef}>
                    <DetailFirstSectionContainer style={{ height: containerHeight }}>
                        <BackButton onClick={() => props.switchToAllModels()} >
                            <BackButtonIcon src={backicon}></BackButtonIcon>
                            Torna ai modelli
                        </BackButton>
                        <SketchFabFrameContainer ref={parentRef}>
                            <ThumbnailContent src={props.data.secondaryData[selectedModel].thumbnails.images[0].url}></ThumbnailContent>
                        </SketchFabFrameContainer>
                        <HeatmapButtonContainer>
                            {props.data.sortedModels[props.selectedModel].sfDoc.name}
                            <HeatmapToggle>
                                <input 
                                    type="range" 
                                    min="0" 
                                    max="1" 
                                    step="0.01" 
                                    value={opacity} 
                                    onChange={(e) => setOpacity(e.target.value)} 
                                />
                                Heatmap
                            </HeatmapToggle>
                        </HeatmapButtonContainer>
                        {heatmapVisible && (
                            <HeatmapContainer>
                                <HeatmapWrapper style={{ opacity: opacity }}>
                                    {heatmapData && (<HeatmapView heatmapData={heatmapData}  parentRef={parentRef}/>)}
                                </HeatmapWrapper>
                            </HeatmapContainer>
                        )}
                    </DetailFirstSectionContainer>
                    <DetailSecondSectionContainer>
                        <SecondSectionWrapper>
                            <ViewsChartContainer>
                                <PanelWrapper>
                                    <PanelLineContent>
                                        {modelchartBarLoaded && (
                                            <Line  data={modelchartData} options={modelchartOptions}/>
                                        )}
                                    </PanelLineContent>
                                </PanelWrapper>
                            </ViewsChartContainer>
                            {/*SmallestPanelData.map(panel => <RenderSmallestPanel {...panel}/>)*/}
                            {[
                            { icon: analyticsviewsicon, description: "Visualizzazioni Totali", value: singlemodelViews || 0, key: "total_views" },
                            { icon: analyticsaricon, description: "Visualizzazioni Totali AR", value: singlemodelViewsAr || 0, key: "total_views_ar" },
                            { icon: analyticsvricon, description: "Visualizzazioni Totali VR", value: singlemodelViewsVr || 0, key: "total_views_vr" },
                            { icon: analyticstimeicon, description: "Tempo medio di caricamento", value: (singlemodelAvgLoadTime/1000).toFixed(2) + " s" || 0, key: "avg_load_time" },
                            { icon: analyticsinteractionicon, description: "Interazioni Totali", value: singlemodelInteractions || 0, key: "total_interactions" },
                            { icon: analyticsinteractionicon, description: "Interazioni medie", value: isNaN(singlemodelInteractions/singlemodelViews) ? 0 : (singlemodelInteractions/singlemodelViews).toFixed(2), key: "avg_interactions" },
                            { icon: analyticstimeicon, description: "Tempo totale di visualizzazione", value: isNaN(singlemodelSumElapsedTime / 1000)? 0 + " s" : (singlemodelSumElapsedTime / 1000) + " s" || 0, key: "total_view_time" },
                            { icon: analyticstimeicon, description: "Tempo medio di visualizzazione", value: isNaN(singlemodelSumElapsedTime/singlemodelViews) ? 0 : (((singlemodelSumElapsedTime/singlemodelViews)/1000).toFixed(2)) + " s", key: "avg_view_time" }
                        ].map(panel => <RenderSmallestPanel {...panel}/>)}
                    
                        </SecondSectionWrapper>
                    </DetailSecondSectionContainer>                 
                </AnalyticsDetailContainer>
            </CenterContainer>
        </CenterWrapper>
        
        </>
    )
}

export default AnalyticsDetail;